import React, { useCallback, useEffect, useState } from "react";

import sendButton from "../../../../static/images/send-btn.png";
import { useScript } from "../../../hooks";
import { Address } from "../../../addressValidator";
import { formatAddressWithApartmentNumber, removePrefix } from "../../../utils";
import { defaultFormData } from "../../../customfunction";
import { Link } from "gatsby";
const ServiceAddress = (props: any) => {
    const { title } = props;
    const [IsReady, setIsReady] = useState(false);
    const [Isaddress, setIsaddress] = useState(false);
    const [formData, setformData] = useState(defaultFormData());
    const [loaded] = useScript(
        "https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js"
    )
    /*
    *Address Mapping
    */
    let onFormStateChanged = useCallback((newFormState: any) => {
        setformData((formData) => ({ ...formData, ...newFormState }))
    }, []);
    let addressChange: any = useCallback(
        (shipping_address: any) => onFormStateChanged({ shipping_address }),
        [onFormStateChanged]
    )
    useEffect(() => {
        let type = window?.pca?.fieldMode?.DEFAULT;
        if (loaded && typeof type !== "undefined" && typeof type !== "undefined") {
            //setformData({ ...formData, shipping_address: {} })
            let fields = [
                {
                    element: "line1",
                    field: "Line1",
                    mode: window.pca.fieldMode.DEFAULT,
                },
                {
                    element: "line11",
                    field: "Line1",
                    mode: window.pca.fieldMode.DEFAULT,
                },
                {
                    element: "apartment",
                    field: "BuildingNumber",
                    mode: window.pca.fieldMode.POPULATE,
                }
            ]
            let options = {
                key: "MM49-DD25-FR41-WY59",
                bar: { showCountry: false, showLogo: true, logoLink: false },
            }
            let control = new window.pca.Address(fields, options)
            control.listen("populate", (a: Address) => {
                addressChange(a);
            })
            setIsReady(true)
            return () => {
                control.destroy()
            }
        }
    }, [loaded, addressChange]);

    let value: any = formData?.shipping_address;
    let formattedAddress: any = value
        ? formatAddressWithApartmentNumber(value)
        : ({} as Address)
    const checkAddress = () => {
        let values: any = formData?.shipping_address;
        setIsaddress(false);
        if (values?.Line1) {
            localStorage.setItem('address_data', JSON.stringify(formData));
            let line2address:any=document.getElementById('line2_address');
            if(line2address){
                line2address.value=formattedAddress?.SubBuilding || ""
            }
            setIsaddress(true);
        }
    }
    useEffect(() => {
        checkAddress();
    }, [formData])
    useEffect(()=>{
        localStorage.removeItem('address_data');
    },[])
    return (
        <>
            <link
                rel="stylesheet"
                type="text/css"
                href="https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=bw11-fu43-na37-by38"
            />
            <div className="container mx-auto">
                <section className="tv-section5">
                    <h2 className="h3 text-white text-center">{title}</h2>
                    <div className="tv-section5-row">
                        <input
                            type="text"
                            className="rounded-lg  focus:z-10 input1"
                            id="address"
                            onFocus={(e) => (e.target.autocomplete = "chrome-off")}
                            name="line1"
                            onBlur={(e)=>{
                                setTimeout(() => {
                                    let ad: any = document.getElementById('home_new_address')
                                    if (ad !== null) {
                                        ad.value = '';
                                    }
                                    let unitField: any = document.getElementById('line2_address')
                                    if (unitField !== null) {
                                        unitField.value = '';
                                    }
                                },1500)
                            }}
                            disabled={!IsReady}
                            required
                            placeholder="Service Address"
                            value={
                                removePrefix(value?.Line1 || "", `${value?.SubBuilding}-`) || ""
                            }
                            onChange={(e) =>
                                addressChange({ ...(value || {}), Line1: e.target.value })
                            }
                        />
                        <input
                            type="text"
                            className="rounded-lg  focus:z-10 input2 max-width80"
                            name="line2"
                            id="unitField" 
                            placeholder={"Unit"}
                            required
                            value={formattedAddress?.SubBuilding || ""}
                            onChange={(e) =>
                                addressChange({ ...(value || {}), SubBuilding: e.target?.value || "" })
                            }
                        />
                        {Isaddress==false ? (
                            <button className="send-btn">
                                <img src={sendButton} alt="send" className="send-btn-icon" />
                            </button>) :
                            <Link to="/join-the-herd"><button className="send-btn">
                                <img src={sendButton} alt="send" className="send-btn-icon" />
                            </button>
                            </Link>
                        }

                    </div>
                </section>
            </div>
        </>
    )
}

export default ServiceAddress
